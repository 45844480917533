<template>
  <div>
    <!-- 弹窗form -->
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false"
    >
      <!-- 表单 -->
      <DrawerComponent
        :button="drawerButton"
        @colse="formShow = false"
        :loading="loading"
        :drawerConfig="drawerConfig"
        @save="requestSave"
      ></DrawerComponent>
      <!-- 客户列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="选择客户"
          size="80%"
          destroy-on-close
          :visible.sync="drawerShowCustomer"
          :modal="false"
          :append-to-body="true"
        >
          <div class="drawer-table">
            <TableComponent
              @searchData="searchCustomer"
              :pagination="tablePaginationCustomer"
              :templateData="tableDataCustomer"
              :table-loading="tableLoadingCustomer"
              :searchConfig="searchConfigCustomer"
              :table-config="tableConfigCustomer"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent
              @clickEvent="beforeSaveSelect"
              :btnType="'primary'"
              :icon="'el-icon-check'"
              >保存
            </buttonComponent>
            <buttonComponent
              @clickEvent="drawerShowCustomer = false"
              :btnType="'danger'"
              :icon="'el-icon-close'"
              >关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>

    <DrawerComponent
      v-if="dmsId"
      :button="drawerButton"
      @colse="formShow = false"
      :loading="loading"
      :drawerConfig="drawerConfig"
      @save="requestSave"
    ></DrawerComponent>
  </div>
</template>

<script>
import { log } from 'mathjs';
import buttonComponent from '../../../../compoment/button-component/button-component';
import TableComponent from '../../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  props: ['dmsId', 'dmsCode'],
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      loading: false,
      id: '',
      code: 'add',
      // ---------------------- 弹窗 - 新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: () => {
              this.requestSave({}, 'Y');
            },
          },
        ],
      },
      drawerConfig: [
        // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: {},
          formConfig: {
            // 弹窗表单配置
            disabled: false,
            items: [
              [
                {
                  label: '单据编号',
                  key: 'billCode',
                  show: false,
                },
                {
                  label: '负责人',
                  key: 'responsibleName',
                  disabled: true,
                },
                {
                  valueType: 'select',
                  label: '审批状态',
                  key: 'approveStatus',
                  path: 'code?dictTypeCode=dms_approval_state',
                  show: false,
                },
                {
                  valueType: 'select',
                  label: '调账类型',
                  key: 'adjustAccountType',
                  path: 'code?dictTypeCode=adjust_account_type',
                },
                {
                  label: '调出客户',
                  key: 'outCustomerName',
                  valueType: 'iconInput',
                  icon: 'el-icon-circle-plus-outline',
                  fn: () => {
                    this.searchCustomer({}, 'out');
                    this.customerType = 'out';
                  },
                },
                {
                  valueType: 'select',
                  label: '调出公司主体',
                  key: 'outCompanyCode',
                  path: 'companyPageList',
                  options: {
                    label: 'companyName',
                    value: 'companyCode',
                  },
                  fn: (value, formData, ref) => {
                    this.$nextTick(() => {
                      this.setFormData(ref[0].selectedLabel, 'outCompanyName');
                    });
                  },
                },
                {
                  label: '调入客户',
                  key: 'enterCustomerName',
                  valueType: 'iconInput',
                  icon: 'el-icon-circle-plus-outline',
                  fn: () => {
                    this.searchCustomer({}, 'enter');
                    this.customerType = 'enter';
                  },
                },
                {
                  valueType: 'select',
                  label: '调入公司主体',
                  key: 'enterCompanyCode',
                  path: 'companyPageList',
                  options: {
                    label: 'companyName',
                    value: 'companyCode',
                  },
                  fn: (value, formData, ref) => {
                    this.$nextTick(() => {
                      this.setFormData(
                        ref[0].selectedLabel,
                        'enterCompanyName',
                      );
                    });
                  },
                },
                {
                  label: '调入方业务员',
                  key: 'enterBusinessmanCode',
                  valueType: 'select',
                  options: {
                    value: 'userName',
                    label: 'fullName',
                  },
                  fn: (val, formData, ref, data) => {
                    data.forEach((i) => {
                      if (i.userName === val) {
                        this.setFormData(i.positionCode, 'enterPositionCode');
                      }
                    });
                  },
                },
                {
                  label: '调账金额',
                  key: 'adjustAccountQuota',
                  inputType: 'number',
                },
                {
                  label: '快递公司',
                  key: 'expressCompanyName',
                },
                {
                  label: '快递单号',
                  key: 'expressListNumber',
                },
                {
                  label: '调账原因',
                  key: 'adjustAccountCause',
                  valueType: 'textarea',
                  itemWidth: '100%',
                },
                {
                  label: '备注',
                  key: 'remark',
                  valueType: 'textarea',
                  itemWidth: '100%',
                },
                {
                  label: '附件',
                  key: 'attachmentList',
                  valueType: 'inputUpload',
                  itemWidth: '100%',
                },
              ],
            ],
          },
          rules: {
            adjustAccountType: [
              {
                required: true,
                message: '请选择调账类型',
                trigger: 'change',
              },
            ],
            outCustomerName: [
              {
                required: true,
                message: '请选择调账客户',
                trigger: 'change',
              },
            ],
            outCompanyCode: [
              {
                required: true,
                message: '请选择调出公司主体',
                trigger: 'change',
              },
            ],
            enterCustomerName: [
              {
                required: true,
                message: '请选择调入客户',
                trigger: 'change',
              },
            ],
            enterCompanyCode: [
              {
                required: true,
                message: '请选择调入公司主体',
                trigger: 'change',
              },
            ],
            enterBusinessmanCode: [
              {
                required: true,
                message: '请选择调入方业务员',
                trigger: 'change',
              },
            ],
            adjustAccountQuota: [
              {
                required: true,
                message: '请输入调账金额',
                trigger: 'blue',
              },
            ],
          },
        },
      ],

      // ----------------------- 弹窗 - 客户列表配置
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '客户名称',
            prop: 'customerName',
          },
          {
            label: '客户编码',
            prop: 'customerCode',
          },
          {
            label: '客户类型',
            prop: 'customerTypeName',
          },
        ],
      },
      searchConfigCustomer: {
        options: [
          {
            valueName: 'customerName',
            placeholder: '请输入客户名称',
          },
          {
            valueName: 'customerCode',
            placeholder: '请输入客户编码',
          },
          {
            valueName: 'customerType',
            placeholder: '请选择客户类型',
            valueType: 'select',
            path: 'code?dictTypeCode=cust_type',
          },
        ],
      },
      tablePaginationCustomer: {
        pageNum: 1,
      },
    };
  },

  created() {
    this.initPageState();

    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    this.id = this.dmsId || this.id;
  },
  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },
  methods: {
    initPageState() {
      const itemFormNames = ['approveStatus', 'billCode'];
      if (this.code === 'view') {
        // 设置查看显示的字段
        this.setFormItemValue(itemFormNames, 'show', true);
      } else {
        this.setFormItemValue(itemFormNames, 'show', false);
      }
      if (this.code !== 'add') {
        // 非新增查询详情
        this.searchDetail();
      } else {
        this.setPeople();
      }
    },
    /**
     * 设置负责人相关信息
     */
    setPeople() {
      this.setFormData(this.userInfo.fullName, 'responsibleName');
      this.setFormData(this.userInfo.userCode, 'responsibleCode');
    },

    /**
     * 查询客户
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData, customerType) {
      this.tableLoadingCustomer = true;
      const { positionCode } = this.userInfo;
      let params = {};
      if (customerType === 'enter' || (this.customerType === 'enter' && (!customerType || customerType === 'enter'))) {
        params = {
          pageSize: 10, ...requestData, actApproveStatus: '3',
        };
      } else {
        params = {
          pageSize: 10, ...requestData, actApproveStatus: '3', positionCode,
        };
      }
      const {
        result: { data, count },
      } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/pageList',
        params,
      );
      this.tableDataCustomer = data;
      this.tableLoadingCustomer = false;
      this.tablePaginationCustomer.total = count;
      if (!this.drawerShowCustomer) {
        this.showDrawer('drawerShowCustomer');
        this.tablePaginationCustomer.pageNum = 1;
      }
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.drawerConfig[0].formConfig.disabled = state;
      this.drawerButton.show = !state;
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id) {
        const { result } = await this.$http.get(
          '/dms/customeradjustaccount/getDetail',
          { id: this.id },
        );
        this.drawerConfig[0].ruleForm = { ...result };
        // await this.searchDocking(result.enterCustomerCode);
        if (result.enterBusinessmanCode) {
          await this.searchCustomerCode(result.enterBusinessmanCode);
        }
        this.setDisabled(this.code === 'view');
      }
    },

    /**
     * 查询业务员
     * @param customerCode {string}
     * @returns {Promise<void>}
     * */
    async searchDocking(customerCode) {
      const { result } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCode',
        { customerCode },
        {
          headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        },
      );
      this.setFormItemValue(['enterBusinessmanCode'], 'data', result);
    },
    /**
     * 查询业务员
     * @param userName {string}
     * @returns {Promise<void>}
     * */
    async searchCustomerCode(userName) {
      const { result } = await this.$http.get('/mdm/mdmUserController/detail', {
        userName,
      });
      this.setFormItemValue(['enterBusinessmanCode'], 'data', [result]);
    },
    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      this.selectTableData = data;
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this[name] = false;
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>}
     * @param state {boolean}
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      if (this.drawerShowCustomer === true && !this.selectMessage()) {
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存客户
     */
    saveSelectCustomer() {
      this.setFormData(
        this.selectTableData[0].customerCode,
        `${this.customerType}CustomerCode`,
      );
      this.setFormData(
        this.selectTableData[0].customerName,
        `${this.customerType}CustomerName`,
      );
      // eslint-disable-next-line no-unused-expressions
      if (this.customerType === 'enter') {
        if (this.selectTableData[0].enterBusinessmanCode) {
          this.searchCustomerCode(this.selectTableData[0].enterBusinessmanCode);
        } else if (this.selectTableData[0].customerCode) {
          this.searchDocking(this.selectTableData[0].customerCode);
        }
      }
      // this.customerType === 'enter' &&this.searchCustomerCode(this.selectTableData[0].enterBusinessmanCode);
      // this.customerType === 'enter' && this.searchDocking(this.selectTableData[0].customerCode);
    },

    /**
     * 提交表单数据
     * @param data {Object}
     */
    async requestSave(data, temporaryFlag) {
      const requestData = {
        ...this.drawerConfig[0].ruleForm,
      };
      if (!requestData.attachmentList || requestData.attachmentList.length === 0) {
        this.$message.error('请上传附件！');
        return false;
      }
      this.loading = true;
      requestData.temporaryFlag = temporaryFlag || 'N';
      const url = this.code === 'edit'
        ? '/dms/customeradjustaccount/updateCustomerAdjustAccount'
        : '/dms/customeradjustaccount/saveCustomerAdjustAccount';
      try {
        await this.$http.post(url, requestData);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },

    /**
     * 设置表单数据
     * @param data {}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data {<void>}
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.forEach((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ebeef5;
}

.drawer-table {
  padding: 20px;
  margin-bottom: 100px;
}

.slot-body {
  display: flex;

  div {
    display: flex;
    align-items: center;
    flex-grow: 2;

    span {
      flex-shrink: 0;
      margin-right: 5px;
    }

    input {
      width: 150px;
    }
  }
}
</style>
